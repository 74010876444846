<template>
  <div class="row" v-if="asignacion">
    <div class="col">
      <!-- Si ya existe reconocimiento de local -->
      <template>
        <!-- PRECIO Y TIEMPO SECCION IMPORTANTE -->
        <div>
          <p class="h5">Precio de Oferta y Tiempo de Entrega</p>
          <hr />
          <div class="row my-5">
            <!-- TIEMPO DE ENTREGA -->
            <div class="col-12 col-md-6 col-lg-4">
              <p class="h5 text-center mb-3 font-weight-bold font-italic">
                Tiempo de Entrega
              </p>
              <b-input-group append="dias hábiles" class="mb-2 mr-sm-2 mb-sm-0">
                <b-form-input
                  size="lg"
                  v-model="deliveryDays"
                  placeholder="Digite cuantos dias tomaría realizar la entrega"
                  @keypress="regexNumberInt"
                  type="number"
                ></b-form-input>
              </b-input-group>

              <p>
                El tiempo de entrega que se generará será de
                <span class="font-weight-bold font-italic">
                  {{ deliveryDays }} dias hábiles</span
                >
              </p>
            </div>

            <!--   OFERTA VALIDA -->
            <div class="col-12 col-md-6 col-lg-4 mt-5 mt-md-0">
              <p class="h5 text-center mb-3 font-weight-bold font-italic">
                Oferta Válida (Días)
              </p>

              <b-input-group append="días" class="mb-2 mr-sm-2 mb-sm-0">
                <b-form-input
                  size="lg"
                  type="number"
                  step="0.01"
                  min="0.00"
                  @keypress="regexNumber"
                  v-model="validOffer"
                  placeholder="Digite el los dias que la oferta tendrá para realizar toda la asignación"
                ></b-form-input>
              </b-input-group>

              <p>
                La cantidad de días que la oferta estará vigente será
                <span class="font-weight-bold font-italic">
                  {{ validOffer ? validOffer : "0" }} días</span
                >
              </p>
            </div>

            <!--  COSTO ADICIONAL -->
            <div class="col-12 col-md-6 col-lg-4 mt-5 mt-md-5 mt-lg-0">
              <p class="h5 text-center mb-3 font-weight-bold font-italic">
                Costo Adicional (Opcional)
              </p>

              <b-input-group prepend="S/." class="mb-2 mr-sm-2 mb-sm-0">
                <b-form-input
                  size="lg"
                  type="number"
                  step="0.01"
                  min="0.00"
                  @keypress="regexNumber"
                  v-model="additionalCost"
                  placeholder="Digite si habrá un costo adicional en la asignación"
                ></b-form-input>
              </b-input-group>

              <p v-if="additionalCost">
                Se agregará un costo adicional de
                <span class="font-weight-bold font-italic">
                  {{
                    additionalCost ? formatoAPrecio(additionalCost) : "S/.0.00"
                  }}
                  a la asignación</span
                >
              </p>
            </div>
          </div>
        </div>

        <hr />

        <!-- TAREAS SECCION IMPORTANTE -->
        <div class="d-flex justify-content-between align-items-center">
          <p class="h5 detalles-asignacion__sub font-italic my-0">
            Partidas registradas
          </p>
          <!-- 
          <button
            type="button"
            v-hide-admin
            v-hide-contratante
            class="btn btn-success btn-sm"
            @click="$bvModal.show('modal-crear-tarea')"
            v-if="allowAddTareas"
          >
            <i class="fas fa-plus mr-2"></i>
            Registrar Partidas
          </button> -->
        </div>

        <p class="small mt-1 text-center font-italic" v-if="loading">
          <template>
            <span
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span class="sr-only"></span>

            <span class="d-inline-block ml-2">Obteniendo Partidas</span>
          </template>
        </p>

        <!-- En caso de tener TAREAS -->
        <!-- MUESTRA DE TAREAS ASIGNADAS -->
        <section class="mt-3" v-else>
          <div class="row" v-if="tareas.length">
            <div
              class="col-md-12 mb-3"
              v-for="tarea in tareas"
              :key="tarea.tareaId"
            >
              <!-- Muestra un card con las tareas, cada card es collapse y muestra info detallada de cada tarea -->
              <b-card class="bg-info">
                <div class="d-flex justify-content-between">
                  <div class="d-flex align-items-center">
                    <a
                      class="text-light"
                      href=""
                      v-b-toggle="tarea.tareaId"
                      @click.prevent
                      variant="info"
                      >{{ tarea.descripcion }}</a
                    >
                    <b-badge
                      v-if="
                        tarea.precioContratista && tarea.precioAdministrador
                      "
                      variant="success"
                      class="ml-2 p-2 badge-text"
                      >Precio colocado</b-badge
                    >
                    <b-badge v-else variant="dark" class="ml-2 p-2 badge-text"
                      >Debe completar</b-badge
                    >
                  </div>

                  <!-- BOTONES DE ACCION -->
                  <div class="d-flex">
                    <button
                      v-hide-admin
                      v-hide-contratante
                      @click="privateOpenEditTarea(tarea)"
                      type="button"
                      class="btn btn-warning btn-sm mr-2 mr-md-4"
                      v-b-tooltip.hover
                      title="Editar Partida"
                    >
                      <i class="fas fa-marker"></i>
                    </button>
                    <button
                      v-hide-admin
                      v-hide-contratante
                      @click="eliminarTareaRegistrada(tarea.tareaId)"
                      type="button"
                      class="btn btn-danger btn-sm"
                      v-b-tooltip.hover
                      title="Eliminar Tarea"
                    >
                      <i class="fas fa-trash-alt"></i>
                    </button>
                  </div>
                </div>

                <b-collapse :id="tarea.tareaId" class="mt-2">
                  <hr />

                  <b-card>
                    <p>
                      <i class="fas fa-calendar-alt"></i>
                      {{ tarea.created_at | moment("DD/MM/YYYY") }}
                    </p>

                    <small class="text-muted">Observaciones:</small>

                    <p class="card-text my-1 pb-1 border-bottom">
                      {{ tarea.observacion }}
                    </p>

                    <div class="row mt-2">
                      <div class="col-md-12">
                        <p class="my-1 pb-1 border-bottom">
                          <span class="small text-muted">Descripción</span>
                          <span class="ml-2">
                            {{ tarea.descripcion }}
                          </span>
                        </p>
                      </div>
                    </div>

                    <div class="row mt-2">
                      <!-- <div class="col-md-4 col-lg-4">
                        <p class="my-1 pb-1 border-bottom">
                          <span class="small text-muted">Categoría:</span>
                          <span class="ml-2">{{
                            tarea.categoria.descripcion
                          }}</span>
                        </p>
                      </div>

                      <div class="col-md-4 col-lg-4">
                        <p class="my-1 pb-1 border-bottom">
                          <span class="small text-muted">Sub categoría:</span>
                          <span class="ml-2">{{
                            tarea.SubCategoria.descripcion
                          }}</span>
                        </p>
                      </div> -->

                      <div class="col-md-4 col-lg-4">
                        <p class="my-1 pb-1 border-bottom">
                          <span class="small text-muted"
                            >Horario asignado:</span
                          >
                          <span class="ml-2">{{
                            tarea.horario === 0 ? "Diurno" : "Nocturno"
                          }}</span>
                        </p>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-4">
                        <p class="mt-3 mb-1 pb-1 border-bottom">
                          <span class="small text-muted">Medidas:</span>
                          <span class="ml-2"
                            >{{ tarea.cantidad }} ({{
                              tarea.unidadMedida
                            }})</span
                          >
                        </p>
                      </div>
                    </div>

                    <div class="row">
                      <div
                        class="col-md-4"
                        v-hide-supervisor
                        v-hide-contratante
                      >
                        <div class="my-1 pb-1 border-bottom">
                          <span class="small text-muted"
                            >Precio administrador:</span
                          >

                          <br />

                          <div class="contenedor-precio">
                            <div class="input-group mb-3">
                              <!-- PARTE SUPERIOR -->
                              <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon1"
                                  >S/</span
                                >
                              </div>

                              <!-- INPUT -->
                              <input
                                type="number"
                                step="0.01"
                                class="form-control"
                                placeholder="Asignar precio"
                                v-model="tarea.precioAdministrador"
                              />
                            </div>
                          </div>

                          <!-- PARTE INFERIOR -->
                          <span class="small font-weight-bold"
                            >Subtotal:
                            {{
                              tarea.precioAdministrador
                                ? formatoAPrecio(
                                    setSubtotal(
                                      tarea.precioAdministrador,
                                      tarea.cantidad
                                    )
                                  )
                                : "Por asignar"
                            }}</span
                          >
                          <div>
                            <!-- <div class="text-right mt-1">
                                      <button type="button" class="btn btn-primary btn-sm" :disabled="actualizandoPrecioAdmin || !tarea.precioAdministrador" @click="asignarPrecioAdministrador">{{ actualizandoPrecioAdmin ? 'Actualizando...' : 'Asignar' }}</button>
                                    </div> -->
                          </div>
                        </div>
                      </div>

                      <!-- <div class="col-md-4" v-hide-contratante>
                        <div class="mt-3 mt-md-2 border-bottom">
                          <div>
                            <span class="small text-muted mb-3"
                              >Precio Supervisor :</span
                            >
                            <br />
                            <span class="contenedor-precio">{{
                              formatoAPrecio(tarea.precioSupervisor)
                            }}</span>
                          </div>

                          <span class="small font-weight-bold"
                            >Subtotal:
                            {{
                              tarea.precioSupervisor
                                ? formatoAPrecio(
                                    setSubtotal(
                                      tarea.precioSupervisor,
                                      tarea.cantidad
                                    )
                                  )
                                : "Por asignar"
                            }}</span
                          >
                        </div>
                      </div> -->

                      <div class="col-md-4">
                        <div class="mt-3 mb-1 mt-md-1 pb-1 border-bottom">
                          <!-- PARTE SUPERIOR -->
                          <div>
                            <span class="small text-muted"
                              >Precio contratista (S/.) :</span
                            >
                            <br />
                            <span
                              class="contenedor-precio font-italic text-muted"
                            >
                              Original:
                              {{
                                tarea.copiaPrecioContratista
                                  ? ` ${formatoAPrecio(
                                      tarea.copiaPrecioContratista
                                    )}`
                                  : "Por asignar"
                              }}</span
                            >

                            <!-- INPUT -->
                            <input
                              type="number"
                              step="0.01"
                              class="form-control mt-1"
                              placeholder="Asignar precio (Contratista)"
                              required
                              @keypress="regexNumber"
                              v-model="tarea.precioContratista"
                            />
                          </div>

                          <!-- PARTE INFERIOR -->
                          <div>
                            <span class="small font-weight-bold"
                              >Subtotal:
                              {{
                                tarea.precioContratista
                                  ? formatoAPrecio(
                                      setSubtotal(
                                        tarea.precioContratista,
                                        tarea.cantidad
                                      )
                                    )
                                  : "Por asignar"
                              }}</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-card>
                </b-collapse>
              </b-card>
            </div>
          </div>
        </section>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { precio } from "@/mixins/precio";
import regexMixin from "@/mixins/regexMixins";
import {
  hideAdmin,
  hideContratante,
  hideSupervisor,
} from "@/directives/roleDirective.js";

import { validationMixin } from "vuelidate";
const {
  required,
  minValue,
  decimal,
  integer,
} = require("vuelidate/lib/validators");

import GetAllTareas from "@/apollo/queries/tareas/GetAllTareas.gql";
import GetAsignaciones from "@/apollo/queries/asignaciones/GetAsignaciones.gql";

export default {
  directives: { hideAdmin, hideContratante, hideSupervisor },

  mixins: [precio, regexMixin, validationMixin],
  data() {
    return {
      id: this.$route.params.id,
      loading: false,
      tareas: [],
      asignacion: null,

      tareaDeleteId: null,
      isEditingTarea: false,
      selectedEditData: null,

      deliveryDays: 10,
      validOffer: 1000,
      additionalCost: null,
    };
  },
  validations: {
    deliveryDays: {
      required,
      minValue: minValue(0),
      integer,
    },
    validOffer: {
       required,
      minValue: minValue(0),
      integer,
    },
    additionalCost: {
      minValue: minValue(0),
    },
    tareas: {
      $each: {
        precioContratista: {
          required,
        },
        precioAdministrador: {
          required,
        },
      },
    },
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapState(["datosUsuarioLogueado"]),
    esAdmin: function() {
      return parseInt(this.datosUsuarioLogueado.tipoUsuario) === 1;
    },
    esSupervisor: function() {
      return parseInt(this.datosUsuarioLogueado.tipoUsuario) === 2;
    },
    esContratista: function() {
      return parseInt(this.datosUsuarioLogueado.tipoUsuario) === 3;
    },
  },
  methods: {
    validate() {
      this.$v.$touch();
      var isValid = !this.$v.$invalid;
      this.$emit(
        "on-validate",
        this.deliveryDays,
        this.validOffer,
        this.additionalCost,
        this.tareas,
        isValid
      );

      if (!isValid) {
        const h = this.$createElement;

        const vNodesMsg = h("p", { class: ["text-center", "mb-0"] }, [
          h("b-spinner", { props: { type: "grow", small: true } }),
          ` Debe ingresar todos los campos como administrador, tiempo de entrega y los precios finales de cada partida.`,
          h("b-spinner", { props: { type: "grow", small: true } }),
        ]);
        // Create the title
        const vNodesTitle = h(
          "div",
          {
            class: ["d-flex", "flex-grow-1", "align-items-baseline", "mr-2"],
          },
          [h("strong", { class: "mr-2" }, "Debe ingresar todos los campos")]
        );

        this.$bvToast.toast([vNodesMsg], {
          title: [vNodesTitle],
          solid: true,
          variant: "danger",
        });
      }

      return isValid;
    },
    setSubtotal(precio, cantidad) {
      return parseFloat(precio) * parseInt(cantidad);
    },
    privateOpenEditTarea(tarea) {
      this.isEditingTarea = true;
      this.selectedEditData = tarea;
    },
    allowAddTareas: function() {
      let status = false,
        tipoUsuario = parseInt(this.datosUsuarioLogueado.tipoUsuario),
        estado = parseInt(this.asignacion.estado);

      if (tipoUsuario === 2 && estado == 3) {
        status = true;
      }

      return status;
    },

    init() {
      return new Promise((resolve) => {
        this.getInfo().then(() => {
          this.getTareas();

          resolve();
        });
      });
    },
    getInfo() {
      return new Promise((resolve) => {
        this.$apollo
          .query({
            query: GetAsignaciones,
            variables: {
              codigoTicket: this.id,
            },
            fetchPolicy: "no-cache",
          })
          .then((response) => {
            this.asignacion = response.data.GetAsignaciones;
            this.deliveryDays = this.asignacion.tiempoEntrega;
            this.validOffer = this.asignacion.ofertaValida;

            resolve();
          });
      });
    },
    eliminarTareaRegistrada(tareaId) {
      this.tareaDeleteId = Number(tareaId);
      this.$bvModal.show("modal-eliminar-tarea");
    },
    getTareas() {
      this.loading = true;

      this.$apollo
        .query({
          query: GetAllTareas,
          variables: {
            numberPaginate: 100,
            page: 1,
            codigoTicket: this.id,
            estado: "",
          },
          errorPolicy: "all",
          fetchPolicy: "no-cache",
        })
        .then((res) => {
          this.tareas = res.data.GetAllTareas.data;

          this.tareas.forEach((item) => {
            item.copiaPrecioContratista = item.precioContratista;
          });

          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.card-body {
  padding: 15px;
}
.badge-text {
  font-size: 9px;
}

@media (min-width: 768px) {
  .badge-text {
    font-size: 13px;
  }
}
</style>
